import * as React from 'react'
import Layout from '../../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import Seo from '../../components/seo'
import SiteWidthWrapper from '../../components/wrappers/siteWidthWrapper'
import CallOut from '../../components/callOut'
import SplashTwo from '../../components/splashTwo'

const EmergPlumbingPage = () => (
  <Layout>
    <Seo
      title="24 Hour Emergency Plumbing Services"
      description="We provide 24 hour residential &amp; commercial repair emergency services for floods, water, mold, etc. damage"
      canonical="https://ecotechrestoration.ca/services/emergency-services/"
    />

    {/* Heros */}
    <div className="heroWrapper">
      <StaticImage
        className="heroImage"
        src="../../images/heros/emergency.jpg"
        quality={95}
        alt="Emergency Restoration Services"
      />
      <div className="overlay" />
      <h1 className="services__heading heroText">
        24 Hour Emergency Repair Services
      </h1>
    </div>

    <SiteWidthWrapper>
      <CallOut>That's right, 24 Hour Emergency Repair Services!</CallOut>
      <p>
        Do you have a residential or commercial repair emergency? Call our
        24-hour emergency response number now and one of our experienced,
        licenced professionals will be sent out immediately to assess and repair
        your emergency.
      </p>
      <h2>What can I do right now while I wait for an expert to arrive?</h2>
      <p>
        This depends on the kind of emergency you're facing. If it's plumbing
        related, we recommend you turn off the main water line or valve for that
        appliance or fixture. Most appliance and fixture shutoff valves can be
        found near the appliance. For example, the sink shutoff valve is
        generally under the sink. The toilet’s shutoff valve is behind the
        toilet.
      </p>
      <p>
        If it's related to sewage, try to remove any rugs and block off the area
        if possible.{' '}
      </p>
      <p className="services__alertP">
        Please use common sense, and, remember, call 911 if your emergency is
        fire or health related.
      </p>
      <h2>Some emergency plumbing problems you might be having:</h2>
      <ul className="services__UlCenter">
        <li>Leaking pipes</li>
        <li>Broken pipes</li>
        <li>Toilet repair / replacement</li>
        <li>Faucet malfunctions</li>
        <li>Drainage backup</li>
        <li>Burst hot water tank</li>
        <li>Clog removal</li>
      </ul>
      <SplashTwo title="Emergency Plumbing Restoration" />
    </SiteWidthWrapper>
  </Layout>
)

export default EmergPlumbingPage
